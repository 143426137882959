<template>
  <div class="home-app">
    <div class="web_main-view">
      <Menu/>
    </div>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',
  components: {
    Menu,
    Footer
  },
  created () {}
}
</script>
<style lang="less">
@import './assets/css/style.css';

</style>
