<template>
  <div class="home-content">
    <div class="homepage-articles" id="home"></div>

    <div class="home_sec1-page" id="aboutUs">
      <div class="col-sm-5">
        <img src="../assets/imgs/lt-bg.jpg" alt="" />
      </div>
      <div class="col-sm-7">
        <div class="case-snippet">
          <br/><br/><br/>
          <h4 class="section-label">{{ Language[$store.state.currentLanguage].aboutUs[0] }}</h4>
          <br/><br/>
          <div style="font-size: 1.45em;">{{ Language[$store.state.currentLanguage].aboutUs[1] }}</div>
          <br/><br/>
          <div class="analytics-text-link">
            <div class="analytics-text-link">
              <div style="font-size: 1.45em;">
                {{ Language[$store.state.currentLanguage].aboutUs[2] }}<br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home_sec-page" id="portfolio">
      <div class="look-portfolio">{{ Language[$store.state.currentLanguage].aboutUs[3] }}</div>
      <div class="sec-content">
        <a class="sec-item" v-for="item in portfolio" :href="item.url" target="_blank">
          <img :src="item.imgUrl">
          <div class="book-title">{{$store.state.currentLanguage ? item.name : item.enName}}</div>
          <div class="qfe_wrapper">{{$store.state.currentLanguage ? item.author : item.enAuthor}}</div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      Language: [{
          aboutUs: [
            'About us',
            'Readabroad, which focuses on providing overseas cutting-edge content, reads foreign best-selling books that' +
            ' have not been introduced at the first time, and keeps pace with the world\'s top ideas.',
            'Sun Siyuan, founder and CEO of Readabroad,Graduated from the University of Southern California (USC), ' +
            'worked in the United States for many years, served as the station master and chief reporter of Sina Finance New York station.',
            'Exhibition of works'
          ]},
        {
          aboutUs: [
            '关于我们',
            '远读重洋，专注提供海外前沿内容的知识服务团队，第一时间精读未引进的国外畅销好书，同步全球顶尖思想。' +
            '曾解读了《原则》《生命3.0》等全球畅销图书。其中，对《原则》是全网第一个阅读量超过10万的该书解读作品。',
            '远读重洋创始人孙思远，毕业于美国南加州大学（USC），曾驻美多年，任新浪财经纽约站站长、首席记者，' +
            '新浪网科技/财经事业部美东地区主管，曾专访巴菲特、格林斯潘、马云等政商领袖。',
            '作品展示'
          ]
        }],
      portfolio:[
        {
          url:'https://mp.weixin.qq.com/s/dj4BwDqg3nqoHWGyM17aOQ',
          imgUrl:'http://www.readabroad.cn/homeImg/img1.jpg',
          name:'《原 则》',
          author:'（美）雷·达里奥',
          enName:'《Principles》',
          enAuthor:'(US) Ray Dalio',
        },{
          url:'https://mp.weixin.qq.com/s?__biz=MzI1MDY2ODIxNg==&mid=2247483920&idx=1&sn=e3260d9119334291b7617293fe213bc8&scene=19#wechat_redirect',
          imgUrl:'http://www.readabroad.cn/homeImg/img2.jpg',
          name:'《极 端 领 导 力》',
          author:'（美）雷夫·巴宾',
          enName:'《Extreme Ownership》',
          enAuthor:'(US) Leif Babin',

        },{
          url:'https://mp.weixin.qq.com/s/ADsR7o393tgnysrSHNvvvg',
          imgUrl:'http://www.readabroad.cn/homeImg/img3.jpg',
          name:'《跳着踢踏舞去上班》',
          author:'（美）卡罗尔·卢米斯',
          enName:'《Tap Dancing to Work》',
          enAuthor:'(US)Carol J.Loomis',
        },{
          url:'https://mp.weixin.qq.com/s/VyKVUyLPzFNPFifncESV1w',
          imgUrl:'http://www.readabroad.cn/homeImg/img4.jpg',
          name:'《创业清单》',
          author:'（美）大卫.罗斯',
          enName:'《The Startup Checklist》',
          enAuthor:'(US)David S.Ross',
        },{
          url:'https://mp.weixin.qq.com/s/2qFgpP4dG-MN1fq3VvGBaQ',
          imgUrl:'http://www.readabroad.cn/homeImg/img5.jpg',
          name:'《5秒法则》',
          author:'（美）梅尔·罗宾斯',
          enName:'《5 Second Rule》',
          enAuthor:'(US) Mel Robbins',
        },{
          url:'https://mp.weixin.qq.com/s/mwu1JR-0inFFEZ8KI3KKYg',
          imgUrl:'http://www.readabroad.cn/homeImg/img6.jpg',
          name:'《阈限思维》',
          author:'（美）戴夫·格雷',
          enName:'《Liminal Thinking》',
          enAuthor:'(US)Dave Gray',
        },{
          url:'https://mp.weixin.qq.com/s/ADsR7o393tgnysrSHNvvvg',
          imgUrl:'http://www.readabroad.cn/homeImg/img7.jpg',
          name:'《巨人的工具》',
          author:'（美）蒂姆·费里斯',
          enName:'《Tools of Titans》',
          enAuthor:'(US) Tim Ferris',
        },{
          url:'https://mp.weixin.qq.com/s/klGO5hb_rdgH7McHTHG6vw',
          imgUrl:'http://www.readabroad.cn/homeImg/img8.jpg',
          name:'《导师天团》',
          author:'（美）蒂姆·费里斯',
          enName:'《Tribe of Mentors》',
          enAuthor:'(US)Tim Ferris',
        }
      ]
    }
  },
  components: {},
  created () {}
}
</script>
