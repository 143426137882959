<template>
  <div>
    <div class="home-small-articles" id="contactUs">
      <div class="art-lft" id="joinUs"></div>
      <div class="art-mid">
        <div class="mid-a">
          {{ contactUs[$store.state.currentLanguage].aboutUs[0] }}
          <span>{{ contactUs[$store.state.currentLanguage].aboutUs[1] }}</span>
        </div>
        <div class="mid-b"> {{ contactUs[$store.state.currentLanguage].aboutUs[2] }} </div>
        <div class="mid-b"> {{ contactUs[$store.state.currentLanguage].aboutUs[3] }} </div>
      </div>
      <div class="art-rit">
        <div class="img-art-center"></div>
        <div class="art-text"> {{ contactUs[$store.state.currentLanguage].aboutUs[4] }} </div>
        <div class="art-text"> {{ contactUs[$store.state.currentLanguage].aboutUs[5] }} </div>
      </div>
    </div>
    <div class="overlay">
      <div>{{ contactUs[$store.state.currentLanguage].aboutUs[7] }}</div>
      <div><a href="https://beian.miit.gov.cn" target="_blank">
        {{ contactUs[$store.state.currentLanguage].aboutUs[6] }}
      </a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
  data () {
    return {
      contactUs: [{
        aboutUs: [
          'Contact us',
          'CONTACT',
          'Address: Room 1002, Chaowai SOHO Block A, No.6 Chaowai Street, Chaoyang District, Beijing',
          'Email: hr@readabroad.cn',
          'Follow us via Wechat',
          'Bring you forward knowledge of the world',
          'Beijing ICP 18035149',
          'Copyright © 2018 Readabroad. All rights reserved.'
        ]},
        {
          aboutUs: [
            '联系我们',
            'CONTACT',
            '地址：北京市朝阳区朝外大街乙6号朝外SOHO A座1002',
            '邮箱：hr@readabroad.cn',
            '关注远读重洋微信公众号',
            '同步全区前沿知识、顶尖思想',
            '京ICP备18035149号-1',
            '版权所有 © 2018 远读重洋 保留全部权力'
          ]
        }]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less"></style>
