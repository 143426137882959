<template>
  <div class="globalHeader">
    <div class="moving-header">
      <div class="cardinal-top">
        <a href="#" class="cardinal-logo">
          <img
            class="cardinal-logo-img"
            alt="Cardinal Health"
            src="@/assets/imgs/logo-main.jpg"
          />
        </a>
      </div>
    </div>
    <div class="menu-class">
      <div class="navigation">
        <ul class="nav primary-nav-bar">
          <li v-for="(item, index) in Language[$store.state.currentLanguage].TitleList ">
            <a :data-title="item" class="main-nav-link" @click="hrefMyMall(index)">
              <span>{{ item }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-btn">
      <a :class="['language', $store.state.currentLanguage ? 'act' : '']" @click="$store.state.currentLanguage= 0 ">EN</a>
      <a :class="['language', $store.state.currentLanguage ? '' : 'act']" @click="$store.state.currentLanguage= 1 ">中文</a>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
export default {
  name: 'Menu',
  props: {
    msg: String
  },
  data () {
    return {
      Language: [
        {
          TitleList: ['Home', 'Portfolio', 'Contact us', 'Join us', 'About us', 'Mall']
        },
        {
          TitleList: ['首页', '作品展示', '联系我们', '加入我们', '关于我们', '商城']
        }
      ],
      hrefUrl:['home', 'portfolio', 'contactUs', 'joinUs', 'aboutUs', 'mall']
    }
  },
  methods: {
    hrefMyMall(flag){
      if(flag+1 === this.hrefUrl.length){
        this.$router.push('/about')
      }else {
        console.log(this.$route.path)
        if(this.$route.path == '/about') this.$router.push('/')
        let that = this
        setTimeout(()=>{
          $("body,html").animate({
            scrollTop: $("#"+that.hrefUrl[flag]).offset().top + 5
          },1000);
        },1)
      }
    }
  },
  components: {},
  created () {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.nav-item.active .nav-text {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.nav-text{
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(0,0,0,0);
  font-size: 16px;
  margin-right: 80px;
  cursor: pointer;
}
.nav-text.act{
  padding-bottom: 14px;
}
.nav-btn{
  margin-left: 120px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.language{
  padding: 5px 0;
  width: 40px;
  font-size: 12px;
  border: 1px solid #424242;
  background: #424242;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.language.act{
  border: 1px solid #424242;
  color: #424242;
  background: #f5f5f5;
}
</style>
